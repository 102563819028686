<template>
  <div class="detail-div">
    <CModal title="Update Industry" size="lg" :show.sync="industryModal" :no-close-on-backdrop="true" color="primary" :hide-footer="true">
      <CCol sm="12">
        <div class="form-group">
          <label class="form__label">Industry</label>
          <v-select :options="industryOptionss" v-model="industryUpdate.industry" @option:selected="industryChange"></v-select>
        </div>
      </CCol>
      <CCol sm="12">
        <div class="form-group" v-show="industryUpdate.category=='Staffs'">
          <label class="form__label">Title
            <required_span/>
          </label>
          <v-select :options="titleOptions"
                    v-model="industryUpdate.title">
          </v-select>
        </div>
      </CCol>
      <template #footer style="display: none;">
        <CButton color="primary" @click="submitIndustry()">Save
        </CButton>
      </template>
    </CModal>
    <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}
    </CAlert>
    <!--Start-->
    <CRow v-show="iAmCompany">
      <CCol md="3">
        <CRow>
          <CCol md="12" class="text-center">
            <img alt="Company Logo" @click="open(profileImage)" :src="profileImage" class="rounded-circle" height="auto"
              width="50%" />
          </CCol>
        </CRow>
      </CCol>
      <CCol md="9" class="align-self-center">
        <CRow>
          <CCol sm="6" md="4" lg="4" v-show="profile.company">
            <p class="custome-labal">{{ $lang.profile.form.company }}</p>
            <p class="custome-detail">{{ profile.company }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.email">
            <p class="custome-labal">{{ $lang.profile.form.email }}</p>
            <p class="custome-detail">{{ profile.email }}
              <i title="Verified" v-if="profile.emailVerified == 1" style="color:green" class="fa fa-check"
                aria-hidden="true"></i>
              <i title="Not Verified" v-if="profile.emailVerified == 0" style="color:red" class="fa fa-times"
                aria-hidden="true"></i>
                <span v-if="profile.emailVerified == 1 && profile.emailVerifiedBy!=null && profile.emailVerifiedBy!=''">
                    <br><small><b>Verified by {{profile.emailVerifiedBy}}</b></small>
                </span>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.mobile">
            <p class="custome-labal">Mobile</p>
            <p class="custome-detail">{{ profile.countryCode }} {{ profile.mobile }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="setCompanyData.panNumber">
            <p v-if='profile.type==="Company"' class="custome-labal">{{ profile.isoCountryCode == "IN" || profile.isoCountryCode == "" ? "Pan Number"
              : "Company Registration Number" }}</p>
               <p v-else class="custome-labal">{{ profile.isoCountryCode == "IN" || profile.isoCountryCode == "" ? "Pan Number"
              : profile.idProofLabel }}</p>
            <!-- <p v-if='profile.type==="Company"' class="custome-detail">{{ setCompanyData.panNumber }}</p> -->
            <p class="custome-detail">{{ setCompanyData.panNumber }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.companyType">
            <p class="custome-labal">{{ $lang.profile.form.companyType }}</p>
            <p class="custome-detail">{{ profile.companyType }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.size">
            <p class="custome-labal">{{ $lang.profile.form.companySize }}</p>
            <p class="custome-detail">{{ profile.size }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.name">
            <p class="custome-labal">{{ $lang.profile.form.name }}</p>
            <p class="custome-detail">{{ profile.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.title">
            <p class="custome-labal">{{ $lang.profile.form.title }}</p>
            <p class="custome-detail">{{ profile.title }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="removeForUser">
            <p class="custome-labal">{{ $lang.profile.form.profileStatus }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(profile.status)">{{ profile.status }}</CBadge>
            </p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.industry">
            <p class="custome-labal">{{ $lang.profile.form.industry }}</p>
            <p class="custome-detail" v-if="profile.status != 'Verified'">{{ profile.industry }}&nbsp;<a style="color: blue; cursor: pointer;" @click="updateIndustry()"><i title="Update Industry" class="fas fa-pencil-alt"></i></a></p>
            <p class="custome-detail" v-else>{{ profile.industry }}</p>
<!--            <p class="custome-detail">{{ profile.industry }}</p>-->
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.subIndustry">
            <p class="custome-labal">{{ $lang.profile.form.subIndustry }}</p>
            <p class="custome-detail">{{ profile.subIndustry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.country">
            <p class="custome-labal">{{ $lang.profile.form.country }}</p>
            <p class="custome-detail">{{ profile.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.state">
            <p class="custome-labal">{{ $lang.profile.form.state }}</p>
            <p class="custome-detail">{{ profile.state }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.city">
            <p class="custome-labal">{{ $lang.profile.form.city }}</p>
            <p class="custome-detail">{{ profile.city }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.skills">
            <p class="custome-labal">{{ $lang.profile.form.skills }}</p>
            <p class="custome-detail">{{ profile.skills }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.website">
            <p class="custome-labal">{{ $lang.profile.form.website }}</p>
            <p class="custome-detail"><a :href="profile.website" target="_blank">{{ profile.website }}</a></p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.address1">
            <p class="custome-labal">{{ $lang.profile.form.address1 }}</p>
            <p class="custome-detail">{{ profile.address1 }}</p>
          </CCol>

        </CRow>
        <CRow>
          <CCol sm="6" md="4" lg="12" v-show="profile.description">
            <p class="custome-labal">{{ $lang.profile.form.description }}</p>
            <p class="custome-detail">{{ profile.description }}</p>
          </CCol>
        </CRow>
        <entity-time-stamp :createdAt="profile.createdAt" :updatedAt="profile.updatedAt"></entity-time-stamp>
      </CCol>
    </CRow>
    <!--End-->

    <CModal size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" color="primary" :hide-footer="true">
      <center>
        <img alt="Profile" class="imageFormat" :src="viewImage" />
      </center>

      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none">Discard
        </CButton>
        <CButton @click="darkModal = false" color="success" style="display: none">Accept
        </CButton>
      </template>
    </CModal>
    <!--Start -->
    <CRow v-if="profile.type === 'Company'">
      <CCol md="12">
        <h4 class="detail-heading" v-if="user.role === 'Admin'"><strong>{{ $lang.profile.heading.adminUser }}</strong>
        </h4>
      </CCol>
    </CRow>



    <br>
    <CRow v-if="profile.type === 'Company'">
      <CCol md="3">
        <CRow>
          <CCol md="12" class="text-center">
            <img alt="User Profile Picture" @click="open(avatar)" :src="avatar" class="rounded-circle" height="auto"
              width="50%" />
          </CCol>
        </CRow>
      </CCol>
      <CCol md="9" class="align-self-center">
        <CRow>
          <CCol sm="6" md="4" lg="4" v-show="user.name">
            <p class="custome-labal">{{ $lang.profile.user.name }}</p>
            <p class="custome-detail">{{ user.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="user.title">
            <p class="custome-labal">{{ $lang.profile.user.title }}</p>
            <p class="custome-detail">{{ user.title }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="user.email">
            <p class="custome-labal">{{ $lang.profile.user.email }}</p>
            <p class="custome-detail">{{ user.email }}
              <i title="Verified" v-if="user.emailVerified == 1" style="color:green" class="fa fa-check"
                aria-hidden="true"></i>
              <i title="Not Verified" v-if="user.emailVerified == 0" style="color:red" class="fa fa-times"
                aria-hidden="true"></i>
                <span v-if="user.emailVerified == 1 && (user.emailVerifiedBy!=null && user.emailVerifiedBy!='')">
                    <br><small><b>Verified by {{user.emailVerifiedBy}}</b></small>
                </span>
            </p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="user.country">
            <p class="custome-labal">{{ $lang.profile.user.country }}</p>
            <p class="custome-detail">{{ user.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="user.state">
            <p class="custome-labal">{{ $lang.profile.user.state }}</p>
            <p class="custome-detail">{{ user.state }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="user.city">
            <p class="custome-labal">{{ $lang.profile.user.city }}</p>
            <p class="custome-detail">{{ user.city }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="user.address1">
            <p class="custome-labal">{{ $lang.profile.user.address1 }}</p>
            <p class="custome-detail">{{ user.address1 }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="user.role">
            <p class="custome-labal">{{ $lang.profile.user.role }}</p>
            <p class="custome-detail">{{ user.role }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="displayUserData.status">
            <p class="custome-labal">{{ $lang.profile.user.status }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(displayUserData.status)">{{ displayUserData.status }}</CBadge>
            </p>
          </CCol>
        </CRow>

        <entity-time-stamp :createdAt="profile.createdAt" :updatedAt="profile.updatedAt"></entity-time-stamp>
      </CCol>
    </CRow>
    <!--End-->

    <!--Start if user of company at company will be displyed at bottom-->
    <!-- Enable if user is not owner -->

    <CRow v-show="iAmUser">
      <CCol md="12">
        <h4 class="detail-heading"><strong> {{ $lang.profile.heading.company }}</strong></h4>
      </CCol>
    </CRow>
    <br>
    <CRow v-show="iAmUser">
      <CCol md="3">
        <CRow>
          <CCol md="12" class="text-center">
            <img alt="Profile Pic" @click="open(profileImage)" :src="profileImage" class="rounded-circle" height="auto"
              width="50%" />
          </CCol>
        </CRow>
      </CCol>
      <CCol md="9" class="align-self-center">
        <CRow>
          <CCol sm="6" md="4" lg="4" v-show="profile.company">
            <p class="custome-labal">{{ $lang.profile.form.company }}</p>
            <p class="custome-detail">{{ profile.company }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.description">
            <p class="custome-labal">{{ $lang.profile.form.description }}</p>
            <p class="custome-detail">{{ profile.description }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.companyType">
            <p class="custome-labal">{{ $lang.profile.form.companyType }}</p>
            <p class="custome-detail">{{ profile.companyType }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.size">
            <p class="custome-labal">{{ $lang.profile.form.companySize }}</p>
            <p class="custome-detail">{{ profile.size }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.name">
            <p class="custome-labal">{{ $lang.profile.form.name }}</p>
            <p class="custome-detail">{{ profile.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.title">
            <p class="custome-labal">{{ $lang.profile.form.title }}</p>
            <p class="custome-detail">{{ profile.title }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.email">
            <p class="custome-labal">{{ $lang.profile.form.email }}</p>
            <p class="custome-detail">{{ profile.email }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="removeForUser">
            <p class="custome-labal">{{ $lang.profile.form.status }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(profile.status)">{{ profile.status }}</CBadge>
            </p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.industry">
            <p class="custome-labal">{{ $lang.profile.form.industry }}</p>
            <p class="custome-detail">{{ profile.industry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.subIndustry">
            <p class="custome-labal">{{ $lang.profile.form.subIndustry }}</p>
            <p class="custome-detail">{{ profile.subIndustry }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.skills">
            <p class="custome-labal">{{ $lang.profile.form.skills }}</p>
            <p class="custome-detail">{{ profile.skills }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.website">
            <p class="custome-labal">{{ $lang.profile.form.website }}</p>
            <p class="custome-detail"><a :href="profile.website" target="_blank">{{ profile.website }}</a></p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-show="profile.country">
            <p class="custome-labal">{{ $lang.profile.form.country }}</p>
            <p class="custome-detail">{{ profile.country }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.state">
            <p class="custome-labal">{{ $lang.profile.form.state }}</p>
            <p class="custome-detail">{{ profile.state }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.city">
            <p class="custome-labal">{{ $lang.profile.form.city }}</p>
            <p class="custome-detail">{{ profile.city }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="profile.address1">
            <p class="custome-labal">{{ $lang.profile.form.address1 }}</p>
            <p class="custome-detail">{{ profile.address1 }}</p>
          </CCol>

        </CRow>
        <entity-time-stamp :createdAt="profile.createdAt" :updatedAt="profile.updatedAt"></entity-time-stamp>

      </CCol>
    </CRow>

    <CRow>

      <CCol sm="3" md="4" lg="12" v-show="profile">
        <div>
          <CButton color="danger" v-on:click="deactiveRow(userId)" v-if="user.active === 1" style="float: right">
            Deactive
          </CButton>
          <CButton color="warning" v-on:click="deactiveRow(userId)" v-if="user.active === 0" style="float: right">
            Active
          </CButton>
          <CButton color="success" v-on:click="verifyEmail(userId)"
            v-if="user.emailVerified === 0 || profile.emailVerified === 0" style="float: right;margin-right:10px;">
            Verify Email/s
          </CButton>
        </div>
      </CCol>
    </CRow>

    <!--End-->

    <!--    <div v-if="user.role=='Admin'">-->
    <!--    <ChildUsers :companyId="profile.companyId" v-if="numberOfUsers > 0"></ChildUsers>-->
    <!--    </div>-->
  </div>
</template>
<script>
import Vue from "vue";
import { serverBus } from "/src/main";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {industry, profile, skills} from "/src/store/url.js";
import { Mixin } from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import EntityTimeStamp from "/src/components/EntityTimeStamp";
import required_span from "@/components/layouts/general/required-span.vue";
function toast({ title, message, type, timeout, cb }) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "DetailTab",
  mixins: [Mixin],
  components: {
    required_span,
    EntityTimeStamp,
  },
  data() {
    return {
      alertMessage: "test",
      displayUserData: [],
      showAlert: false,
      dismissCountDownS: 0,
      userId: "",
      viewImage: "",
      largeModal: false,
      iAmUser: "",
      iAmCompany: "",
      removeForUser: true,
      numberOfUsers: "",
      submitted: false,
      Disabled: true,
      err_msg: "",
      profileImage: "",
      avatar: "",
      selectedSkills: [],
      dismissCountDown: 0,
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      companyTypeOptions: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      statusOptions: [],
      userOptions: [],
      getIndustries: [],
      skillsOptions: [],
      setCompanyData: [],
      self: this,
      module: profile,
      moduleSkills: skills,
      iAm: '',
      profile: {
        companyOrIndividualName: "",
        name: "",
        title: "",
        company: "",
        subIndustry: "",
        companyType: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        industry: "",
        user: "",
        status: "",
        skills: "",
        userId: "",
        email: "",
        companyId: "",
        role: "",
        type: "",  //Company Or Individual
        description: "",
        size: "",
        mobile: "",
        countryCode: "",
        isoCountryCode: "",
        idProofLabel:"",
        emailVerified:"",
        emailVerifiedBy:""
      },
      user: {
        image: "",
        name: "",
        title: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        status: "",
        email: "",
        countryCode: "",
        role: "",
        active: "",
      },
      industryUpdate:{
        industry:"",
        title:"",
        category:"",
      },
      industryOptionss:[],
      titleOptions:[],
      industryModal: false,
      moduleIndustry:industry,
    };
  },
  mounted() {
    const statusType = {
      PENDING: "Pending",
      VERIFIED: "Verified",
      BLOCK: "Block",
    };
    let self = this;
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptionss.push({value: value.id, label: value.industry});
        }
      });
    });
    self.statusOptions.push(
      { value: 1, label: statusType.PENDING },
      { value: 2, label: statusType.VERIFIED },
      { value: 3, label: statusType.BLOCK }
    );
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    const id = this.$route.params.id;

    if (this.$route.params.id) {
      self.userId = this.$route.params.id;
    } else if (this.$route.params.user_id) {
      self.userId = this.$route.params.user_id;
    }

    self.dismissCountDown = 0;
    self.editId = id;

    axios.get(this.listUrlApi(this.moduleSkills)).then((response) => {
      response.data.data.map(function (value, key) {
        self.skillsOptions.push({ value: value.id, label: value.title });
      });
    });

    axios
      .get(this.viewUrlApi(this.module, self.userId))
      .then((response) => {
        // eslint-disable-next-line no-empty
        console.log(response.data);
        if (response.data.data) {
          self.numberOfUsers = response.data.data.numberOfUsers;
          serverBus.$emit('number_of_admin_users', self.numberOfUsers); //send the Company or Individual.
          if (response.data.data.parentId > 0) {
            localStorage.setItem("iAmUser", "true");
            self.removeForUser = false;
            self.iAmUser = true;
          } else {
            self.iAmCompany = true;
          }

          /* User related Information */
          let userResponseData = response.data.data;
          self.displayUserData = userResponseData;
          self.user.name = userResponseData.name;
          self.user.role = userResponseData.role;
          self.user.title = userResponseData.title;
          self.user.active = userResponseData.active;
          self.user.email = userResponseData.email;
          self.user.address1 = userResponseData.address1;
          self.user.state = userResponseData.stateName;
          self.user.city = userResponseData.cityName;
          self.user.country = userResponseData.countryName;
          self.user.emailVerified = userResponseData.emailVerified;
          self.user.emailVerifiedBy = userResponseData.emailVerifiedBy;
          self.user.emailVerifiedAt = userResponseData.emailVerifiedAt;
          self.avatar = userResponseData.avatar;

          localStorage.setItem('userName', userResponseData.name);
          localStorage.setItem('userId', userResponseData.id);
          serverBus.$emit('send_user_with_company_name', userResponseData.userWithCompany); //send the Company or Individual.
          serverBus.$emit('send_role', userResponseData.role); //send the Company or Individual.
          /* End */

          let responseData = response.data.data.profile;
          self.setCompanyData = responseData;
          let list = [];
          responseData.skills.map(function (value, key) {
            list.push(value.title);
          });

          self.profile.skills = list.join(", ");
          self.profile.companyOrIndividualName = responseData.company;
          self.profile.website = responseData.website;
          if (responseData.website != "" && responseData.website !== null) {
            if ((responseData.website).indexOf("http://") == 0 || (responseData.website).indexOf("https://") == 0) {
              self.profile.website = responseData.website;
            } else {
              self.profile.website = "https://" + responseData.website;
            }
          }
          self.profile.companyId = responseData.companyId ?? '';
          axios.get('company/'+self.profile.companyId).then((responseD) => {
            self.industryUpdate.industry = {
              value: responseD.data.data.industryId,
              label: responseD.data.data.industry,
            };
            axios.get('industry/title/'+responseD.data.data.industryId).then((response) => {
              self.titleOptions = [];
              self.industryUpdate.title = "";
              response.data.data.map(function (value, key) {
                self.titleOptions.push({ value: value.id, label: value.title });
              });
              if(responseD.data.data.category == 'Staffs')
              {
                self.industryUpdate.title = {
                  value: responseD.data.data.titleId,
                  label: responseD.data.data.title,
                };
              }
            });
            self.industryUpdate.category = responseD.data.data.category;
          });
          self.profile.size = responseData.size ?? '';
          self.profile.userId = responseData.id;
          self.profile.description = responseData.description;
          self.profile.name = responseData.name;
          self.profile.type = responseData.type;
          self.profile.mobile = userResponseData.mobile;
          self.profile.countryCode = userResponseData.countryCode;
          self.profile.isoCountryCode = userResponseData.isoCountryCode;
          self.profile.idProofLabel = userResponseData.idProofLabel;
          self.profile.emailVerified = responseData.emailVerified;
          self.profile.emailVerifiedBy = responseData.emailVerifiedBy;
          self.profile.emailVerifiedAt = responseData.emailVerifiedAt;

          if (responseData.type === 'Company') {
            serverBus.$emit('send_profile_name', responseData.company); //send the company name or individial name
          } else {
            serverBus.$emit('send_profile_name', userResponseData.userWithCompany); //send the company name or individial name
          }
          serverBus.$emit('send_the_type', responseData.type); //send the Company or Individual.

          serverBus.$emit('send_company_id', self.profile.companyId); //send the Company or Individual.


          self.profile.role = userResponseData.role;
          self.profile.email = responseData.email;
          self.profile.title = responseData.title;
          self.profile.company = responseData.company;
          self.profile.address1 = responseData.address1;
          self.profile.industry = responseData.industryTitle;
          self.profile.status = responseData.industryTitle;
          self.profileImage = responseData.type == "Company" ? responseData.image : userResponseData.avatar;
          self.profile.state = responseData.stateName;
          self.profile.city = responseData.cityName;
          self.profile.country = responseData.countryName;
          self.profile.status = responseData.profileStatus;
          self.profile.isVerified = responseData.isVerifiedId;
          let listSubIndustry = [];

          responseData.subIndustry.map(function (value, key) {
            listSubIndustry.push(value.label);
          });
          self.profile.subIndustry = listSubIndustry.join(", ");
          self.profile.user = responseData.name;
          self.profile.companyType = responseData.companyTypeTitle;

          store.commit("showLoader", false); // Loader Off
        }
      })
      .catch(function (error) {
        store.commit("showLoader", false); // Loader Off
      });
  },
  methods: {
    industryChange(){
      let self = this;
      axios.get('industry/title/'+self.industryUpdate.industry.value).then((response) => {
        self.titleOptions = [];
        self.industryUpdate.title = "";
        response.data.data.map(function (value, key) {
          self.titleOptions.push({ value: value.id, label: value.title });
        });
      });
    },
    submitIndustry(){
      let self = this;
      const formdata = {};
      formdata.industryId= self.industryUpdate.industry.value;
      if(self.industryUpdate.title != "")
      {
        formdata.titleId= self.industryUpdate.title.value;
      }
      console.log(formdata);
      axios.post('industry/update/'+self.profile.companyId,formdata).then((response) => {
        self.industryModal = false;
        window.location.reload();
      });
    },
    updateIndustry(){
      let self = this;
      self.industryModal = true;
    },
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
    deactiveRow(item) {
      let self = this;
      this.$swal({
        title: this.$lang.common.deactive_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.deactive_swal.yes_delete,
        cancelButtonText: this.$lang.common.deactive_swal.cancel,
      }).then((result) => {
        if (result.value) {
          axios.post('users/child/deactive/' + item, {
            creater: true
          }).then((response) => {
            if (response.data.code === 200) {
              self.alertMessage = response.data.message;
              self.dismissCountDownS = 10;
              location.reload();
            }
          });
        }
      });
    },
    verifyEmail(id) {
      let self = this;
      this.$swal({
        title: this.$lang.common.deactive_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.deactive_swal.yes_delete,
        cancelButtonText: this.$lang.common.deactive_swal.cancel,
      }).then((result) => {
        if (result.value) {
          axios.post('users/verify-emails-manually', {
            userId: id
          }).then((response) => {
            if (response.data.code === 200) {
              self.alertMessage = response.data.message;
              self.dismissCountDownS = 10;
              location.reload();
            }
          });
        }
      });
    },
  },
};
</script>
